import { Container } from 'src/components/Container/Container'

import type { PlaceholderModule } from 'src/types/Module'

export const Placeholder = ({ height, fullWidth = false, bgColor }: PlaceholderModule) => (
  <Container
    wide={fullWidth}
    style={{ marginTop: 0, marginBottom: 0, height: height || undefined, backgroundColor: bgColor || undefined }}
  />
)
