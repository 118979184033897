/* eslint-disable max-len */
import Head from 'next/head'
import { NextSeo } from 'next-seo'

import { config } from 'src/config'
import { htmlRemove } from 'src/functions/string/html'

import type { Post } from 'src/types/Post'

interface PostSEOProps {
  post: Post
}

export const PostSEO = ({ post }: PostSEOProps) => {
  const { author, excerpt, header, seo, sharing, title } = post
  const images = []

  if (sharing && sharing.image) {
    images.push({
      url: `https://cdn.coffeecircle.com/${sharing.image.id}/-/stretch/off/-/resize/1024x/${sharing.image.filename}`,
    })
  } else if (header.image) {
    images.push({
      url: `https://cdn.coffeecircle.com/${header.image.id}/-/stretch/off/-/resize/1024x/${header.image.filename}`,
    })
  }

  return (
    <>
      <NextSeo
        title={htmlRemove(title)}
        titleTemplate={config.nextSeo.titleTemplate}
        description={seo.metaDescription ? seo.metaDescription.substring(0, 240) : undefined}
        noindex={seo.noIndex}
        openGraph={{
          type: 'article',
          title: sharing ? sharing.title || title : title,
          site_name: sharing ? sharing.title || title : title,
          description: sharing ? excerpt : seo.metaDescription || '',
          images,
          article: { authors: author.name ? [author.name] : [] },
        }}
      />

      <Head>
        {/* Render the canonical and hreflang tags */}
        {seo.canonicals.map((attrs) => (
          <link key={`canonical.${attrs.rel}.${attrs.hrefLang}`} {...attrs} />
        ))}
      </Head>
    </>
  )
}
