import clsx from 'clsx'

import { Container } from 'src/components/Container/Container'

import styles from './Textblock.module.scss'

import type { TextblockModule } from 'src/types/Module'

export const Textblock = ({ text, centered, dropcap, intro }: TextblockModule) => {
  text = text.replace(/<script.*?src="(.*?)".*?<\/script>/g, '')

  return (
    <Container
      className={clsx(
        'textblock',
        styles.textblock,
        centered && styles.centered,
        intro && dropcap && styles.dropcap,
        intro && 'intro',
      )}
      dangerouslySetInnerHTML={{ __html: text }}
      suppressHydrationWarning
    />
  )
}
