export const POST_TYPE_GUIDE = 'guide'
export const POST_TYPE_PAGE = 'page'
export const POST_TYPE_BLOG_POST = 'post'
export const POST_TYPE_PRODUCT_TEST = 'product-test'
export const POST_TYPE_CATEGORY = 'category'
export const POST_TYPE_PRODUCT = 'product'

export enum PurchaseType {
  oneTime = 'oneTime',
  subscription = 'subscription',
}
