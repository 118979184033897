import clsx from 'clsx'

import { Container } from 'src/components/Container/Container'
import { Headline as HeadlineComponent } from 'src/components/Headline/Headline'

import styles from './Headline.module.scss'

import type { HeadlineModule } from 'src/types/Module'

export const Headline = ({
  headline,
  subline,
  size,
  alignment = 'alignleft',
  anchor,
  divider = false,
}: HeadlineModule) => {
  const isCentered = alignment === 'aligncenter'
  const isRight = alignment === 'alignright'

  return (
    <Container className={clsx('headlineblock', isCentered && styles.center, isRight && styles.right)}>
      <HeadlineComponent variant="h3" id={anchor} className={clsx(styles.headline, size, divider && styles.divider)}>
        {headline}
      </HeadlineComponent>
      {subline && (
        <HeadlineComponent variant="sub" className={styles.subline}>
          {subline}
        </HeadlineComponent>
      )}
    </Container>
  )
}
