/* eslint-disable jsx-a11y/alt-text */
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'

import { Headline } from 'src/cms/Headline/Headline'
import { Image } from 'src/cms/Image/Image'
import { Placeholder } from 'src/cms/Placeholder/Placeholder'
import { PostColumn } from 'src/cms/PostColumn/PostColumn'
import { Textblock } from 'src/cms/Textblock/Textblock'
import { Language } from 'src/enum/Language'

import type { ComponentType } from 'react'
import type { NewsletterProps } from 'src/cms/Newsletter/Newsletter'
import type { CmsProductGridProps } from 'src/cms/ProductGrid/CmsProductGrid'
import type {
  AccordionModule,
  ColumnsModule,
  GalleryGridModule,
  GalleryTimelineModule,
  HeadlineModule,
  HtmlModule,
  ImageModule,
  InfoboxModule,
  Module,
  PlaceholderModule,
  ProductGridModule,
  TextblockModule,
  VideoModule,
} from 'src/types/Module'

const Accordion: ComponentType<AccordionModule> = dynamic(() =>
  import('src/cms/Accordion/Accordion').then((mod) => mod.Accordion),
)
const Gallery: ComponentType<GalleryGridModule> = dynamic(() =>
  import('src/cms/Gallery/Gallery').then((mod) => mod.Gallery),
)
const Timeline: ComponentType<GalleryTimelineModule> = dynamic(() =>
  import('src/cms/Timeline/Timeline').then((mod) => mod.Timeline),
)
const Html: ComponentType<HtmlModule> = dynamic(() => import('src/cms/Html/Html').then((mod) => mod.Html))
const Infobox: ComponentType<InfoboxModule> = dynamic(() =>
  import('src/cms/Infobox/Infobox').then((mod) => mod.Infobox),
)
const Newsletter: ComponentType<NewsletterProps> = dynamic(() =>
  import('src/cms/Newsletter/Newsletter').then((mod) => mod.Newsletter),
)
const CmsProductGrid: ComponentType<CmsProductGridProps> = dynamic(() =>
  import('src/cms/ProductGrid/CmsProductGrid').then((mod) => mod.CmsProductGrid),
)
const Video: ComponentType<VideoModule> = dynamic(() => import('src/cms/Video/Video').then((mod) => mod.Video))

interface PostModuleProps {
  module: Module
  isProductModule: boolean
}

export const PostModule = ({ module, isProductModule }: PostModuleProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const layout = module.acf_fc_layout
  const shouldShowNewsletterBar = language === Language.de

  switch (layout) {
    case 'headline':
      return <Headline {...(module as HeadlineModule)} />
    case 'textblock':
      return <Textblock {...(module as TextblockModule)} />
    case 'image':
      return <Image {...(module as ImageModule)} />
    case 'video':
      return <Video {...(module as VideoModule)} />
    case 'placeholder':
      return <Placeholder {...(module as PlaceholderModule)} />
    case 'html':
      return <Html {...(module as HtmlModule)} />
    case 'gallery':
      switch (module.type) {
        case 'grid':
          return <Gallery {...(module as GalleryGridModule)} />
        case 'slider':
          return <Gallery {...(module as GalleryGridModule)} /> // slider is deprecated in favor of grid
        case 'timeline':
          return <Timeline {...(module as GalleryTimelineModule)} />
        default:
          return null
      }
    case 'organism':
      switch (module.organism) {
        case 'newsletter':
          if (shouldShowNewsletterBar) return <Newsletter newsletterType={module.subOrganismNewsletter} />
          break
        default:
          return null
      }
      return null
    case 'accordion':
      return <Accordion {...(module as AccordionModule)} />
    case 'infobox':
      return <Infobox {...(module as unknown as InfoboxModule)} />
    case 'product-grid':
      return <CmsProductGrid {...(module as ProductGridModule)} />
    case 'columns':
      return <PostColumn {...(module as ColumnsModule)} isProductModule={isProductModule} />
    default:
      return null
  }
}
