/* eslint-disable react/no-array-index-key */
import clsx from 'clsx'

import { Container } from 'src/components/Container/Container'
import { Image } from 'src/components/Image/Image'
import { PostModule } from 'src/components/Post/PostModule'
import { config } from 'src/config'

import styles from './PostColumn.module.scss'

import type { ColumnsModule } from 'src/types/Module'

export const PostColumn = ({
  column,
  fullWidth = false,
  wrapContent = false,
  bgColor,
  bgImageOpacity,
  bgImage,
  isProductModule = false,
}: ColumnsModule) => (
  <div
    className={clsx(
      styles.columns,
      bgColor === 'dark' && styles.dark,
      bgImage && styles.hasImage,
      !isProductModule && styles.nonProductModule,
    )}
  >
    {bgImage && (
      <Image
        identifier={bgImage.id}
        filename={bgImage.filename}
        alt={bgImage.alt}
        title={bgImage.title}
        srcSet={[600, 1200, 2000]}
        sizes={`(min-width: ${config.breakpoints.lg}px) 100vw, 200px`} // low resolution image on mobile for SEO
        width={`${bgImage.width}`}
        height={`${bgImage.height}`}
        responsive
        background
        cover
        lazy
        style={{ opacity: bgImageOpacity }}
      />
    )}
    <Container className={clsx(styles.inner, wrapContent && fullWidth && styles.limited)}>
      {column.map((col, i) => (
        <div
          key={i}
          className={clsx(
            styles.column,
            col.column_width === '2' && styles.double,
            col.column_width === '3' && styles.triple,
          )}
        >
          {col.column_module.modules_and_components.map((module, j) => (
            <PostModule module={module} key={j} isProductModule={isProductModule} />
          ))}
        </div>
      ))}
    </Container>
  </div>
)
