import dynamic from 'next/dynamic'
import { useEffect, type ComponentType } from 'react'

import { Header } from 'src/cms/Header/Header'
import { PostModule } from 'src/components/Post/PostModule'
import { PostSEO } from 'src/components/Post/PostSEO'
import { useLocale } from 'src/contexts/LocaleContext'
import { getBlogPage, getContentPage } from 'src/functions/util/dataLayer'
import { useCartQuery } from 'src/hooks/useCartQuery'

import type { AuthorProps } from 'src/cms/Author/Author'
import type { AuthorSummaryProps } from 'src/cms/AuthorSummary/AuthorSummary'
import type { Post as IPost, PostBrewGuide } from 'src/types/Post'

const Author: ComponentType<AuthorProps> = dynamic(() => import('src/cms/Author/Author').then((mod) => mod.Author))
const AuthorSummary: ComponentType<AuthorSummaryProps> = dynamic(() =>
  import('src/cms/AuthorSummary/AuthorSummary').then((mod) => mod.AuthorSummary),
)
const BrewGuide: ComponentType<{ brewguide: PostBrewGuide }> = dynamic(() =>
  import('src/cms/BrewGuide/BrewGuide').then((mod) => mod.BrewGuide),
)

interface PostProps {
  post: IPost
  type: 'content' | 'blog'
}

export const Post = ({ post, type }: PostProps) => {
  const { language } = useLocale()
  const {
    isFetched: cartIsFetched,
    computed: { isLoggedIn },
  } = useCartQuery()

  useEffect(() => {
    if (cartIsFetched) {
      const dataLayerPayload = type === 'blog' ? getBlogPage(isLoggedIn) : getContentPage(isLoggedIn)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(dataLayerPayload)
    }
  }, [cartIsFetched])

  const { author, category } = post
  const categoryUrl = `/${language}/a/${category?.slug}`
  const hasBrewGuide = Boolean(post.brewguide)

  return (
    <article className="cms">
      <PostSEO post={post} />
      <Header {...post} />

      {author.name && (
        <Author date={post.date} categoryName={category?.name} categoryUrl={categoryUrl} author={author} />
      )}

      {hasBrewGuide && <BrewGuide brewguide={post.brewguide as PostBrewGuide} />}

      {post.modules_and_components.map((module, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PostModule key={index} module={module} isProductModule={false} />
      ))}

      {author.imageId && author.description && <AuthorSummary author={author} />}
    </article>
  )
}
