import clsx from 'clsx'

import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { Image as ImageComponent } from 'src/components/Image/Image'
import { Text } from 'src/components/Text/Text'
import { config } from 'src/config'

import styles from './Image.module.scss'

import type { ImageModule } from 'src/types/Module'

export const Image = ({
  image,
  fullWidth = false,
  gradient = false,
  link,
  linkDescription,
  showCaption = false,
  theme = 'light',
  title: headline,
  opacity,
  overlay = false,
}: ImageModule) => {
  if (!image) return null

  const FigureOuter = link ? 'a' : 'div'
  const { id, filename, alt, title, width, height, caption } = image

  return (
    <Container wide={fullWidth}>
      <FigureOuter className={clsx(styles.figureOuter)} href={link}>
        <figure
          className={clsx(
            styles.figure,
            gradient && styles.gradient,
            theme === 'dark' && styles.dark,
            opacity && opacity < 1 && styles.opacity,
          )}
        >
          <ImageComponent
            identifier={id}
            filename={filename}
            alt={alt}
            title={title}
            srcSet={[400, 800, 1600]}
            sizes={`(min-width: ${config.breakpoints.lg}px) 100vw, 200px`} // low resolution image on mobile for SEO
            width={`${width}`}
            height={`${height}`}
            responsive
            lazy
            style={{ opacity }}
          />
          {headline && overlay && (
            <Headline variant="h2" className={styles.title}>
              {headline}
            </Headline>
          )}
          {linkDescription && overlay && (
            <Text variant="p" className={styles.linkDescription}>
              {linkDescription}
            </Text>
          )}
          {showCaption && <figcaption className={styles.caption}>{caption}</figcaption>}
        </figure>
      </FigureOuter>
    </Container>
  )
}
