import clsx from 'clsx'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'

import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { Image } from 'src/components/Image/Image'
import { config } from 'src/config'
import { POST_TYPE_BLOG_POST } from 'src/constants'

import styles from './Header.module.scss'

import type { PostHeader, PostType, PostTag } from 'src/types/Post'

export interface HeaderProps {
  header: PostHeader
  type: PostType
  title: string
  category?: Pick<PostTag, 'name' | 'slug'> | null
}

export const Header = ({
  type,
  title,
  category,
  header: { image, isSimple, showTitle, opacity, position },
}: HeaderProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const imageOpacity = opacity || 0.5
  const imagePosition = position || 'center center'

  return (
    <header className={clsx(styles.header, isSimple && styles.simple, image && styles.hasImage)}>
      {image && (
        <Image
          identifier={image.id}
          filename={image.filename}
          alt={image.alt}
          title={image.title}
          srcSet={[600, 1200, 2000]}
          sizes={`(min-width: ${config.breakpoints.lg}px) 100vw, 200px`} // low resolution image on mobile for SEO
          width={`${image.width}`}
          height={`${image.height}`}
          responsive
          cover
          style={{ opacity: imageOpacity, objectPosition: imagePosition }}
          preload
        />
      )}

      {showTitle && (
        <Container className={styles.headerContent}>
          {type === POST_TYPE_BLOG_POST && !isSimple && (
            <a href={`/${language}/a/${category?.slug}`} className={styles.headerTag}>
              {category?.name}
            </a>
          )}

          <Headline variant="h1" className={clsx(styles.headerHeadline, isSimple && styles.simple)}>
            <span dangerouslySetInnerHTML={{ __html: title }} suppressHydrationWarning />
          </Headline>

          {!isSimple && (
            <>
              <Head>
                <link rel="preload" as="image" href="/assets/svg/header-triangle.svg" />
              </Head>
              <img className={styles.headerSvg} src="/assets/svg/header-triangle.svg" alt="triangle" />
            </>
          )}
        </Container>
      )}
    </header>
  )
}
